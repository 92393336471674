import request from '@/utils/request'

// 设为日报
export function setNewsIsRep (data) {
  return request({
    url: '/data/setNewsIsRep',
    method: 'POST',
    data
  })
}
// 设为情报
export function addIntelligence (data) {
  return request({
    url: '/intelligence/addIntelligence',
    method: 'POST',
    data
  })
}
// 导出
export function dataExcelExport (data) {
  return request({
    url: '/data/dataExcelExport',
    method: 'POST',
    data
  })
}
// 推送新闻
export function PushNews (data) {
  return request({
    url: '/pushNews/createPushNews',
    method: 'POST',
    data
  })
}
// 通过sn与标签id创建关系
export function saveMarkRelationship (data) {
  return request({
    url: '/data/setOneNewsMark',
    method: 'POST',
    data
  })
}
// 通过项目id查询所有标签
export function listMarkGroupByCid (data) {
  return request({
    url: '/mark/tsmark/selectMarkAndGroup',
    method: 'POST',
    data
  })
}

// 创建新标签
export function saveMark (data) {
  return request({
    url: '/mark/tsmarkgroup/saveMark',
    method: 'POST',
    data
  })
}
// 移除全部标签
export function removeAllMark (data) {
  return request({
    url: '/data/deleteOneDateMark',
    method: 'POST',
    data
  })
}
// 查询项目下所有的分类
export function selectAllType (data) {
  return request({
    url: '/mark/tsmark/selectAllType',
    method: 'POST',
    data
  })
}
// 查询项目下所有的分类
export function selectAllType3 (data) {
  return request({
    url: '/mark/tsmark/selectAllType3',
    method: 'POST',
    data
  })
}
export function selectAllType2 (data) {
  return request({
    url: '/mark/tsmark/selectAllType2',
    method: 'POST',
    data
  })
}
export function selectnNodeBySn (data) {
  return request({
    url: '/data/nodeBySn',
    method: 'POST',
    data
  })
}
export function getAutoReportSet (data) {
  return request({
    url: '/report/getSet',
    method: 'POST',
    data
  })
}
export function updateAutoReportSet (data) {
  return request({
    url: '/report/setRule',
    method: 'POST',
    data
  })
}
