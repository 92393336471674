import request from '@/utils/request'

// 设为追踪，审核通过
export function setTrack (data) {
  return request({
    url: '/datatrace/setTrack',
    method: 'POST',
    data
  })
}

// 追踪列表
export function getTrackList (data) {
  return request({
    url: '/datatrace/listDatatrace',
    method: 'POST',
    data
  })
}
// 审核列表
export function getApplyList (data) {
  return request({
    url: '/datatrace/applyListDatatrace',
    method: 'POST',
    data
  })
}
// 追踪详情
export function getDetail (data) {
  return request({
    url: '/datatrace/detail',
    method: 'POST',
    data
  })
}
// 取消追踪，审核失败
export function setUntrack (data) {
  return request({
    url: '/datatrace/setUntrack',
    method: 'POST',
    data
  })
}
export function refuseTrack (data) {
  return request({
    url: '/datatrace/refuseTrack',
    method: 'POST',
    data
  })
}
