<template>
  <div class="detail-wrap" @click="handleLeave" v-loading.fullscreen.lock="fullscreenLoading">
      <div v-if="el_show">
        <h2 class="title" v-html="article.title"></h2>
              <div class="tags">
                <el-tag size="mini" effect="dark" type="danger" v-show="article.isWarning">预警</el-tag>
<!--                <el-tag size="mini" effect="dark" type="defult" v-show="article.isReport">日报</el-tag>-->
<!--                <el-tag size="mini" effect="dark" type="info" v-show="article.isIntelligence">情报</el-tag>-->
               <el-tag size="mini" type="defult" v-if="article.isDanger===0">正面</el-tag>
               <el-tag size="mini" type="danger" v-if="article.isDanger===1">负面</el-tag>
               <el-tag size="mini" type="warning" v-if="article.isDanger===2">敏感</el-tag>
               <el-button class="operation" type="text" @click.stop="showPanel = true">操作
                 <div class="oneOperationPanel" v-show="showPanel">
                     <ul class="oneOperationUl">
                       <li @click="showOneEmotionPanel=true;hideOneMarkPanel();hideNode()">选择为<i class="el-icon-caret-right"
                           style="padding-left: 32px"></i>
                         <div class="showOneEmotionPanel" v-if="showOneEmotionPanel">
                           <ul class="oneEmotionUl">
                             <li @click.stop="setOneEmotion(2)">敏感</li>
                             <li @click.stop="setOneEmotion(1)">负面</li>
                             <li @click.stop="setOneEmotion(0)">正面</li>
                           </ul>
                         </div>
                       </li>
                       <li @click="showOneDelete()">删除</li>
                       <li @click="setTrack()">追踪</li>

                     </ul>
                   </div>
                 </el-button>
              </div>
            <br>
              <div class="msg">
                <span class="dateTime">{{formatDate(article.publishdateStd)}}</span>
                <span class="source">{{article.sourceName}}</span>
                <el-link :href="article.url" target="_blank" style="vertical-align: text-top;">查看原文&gt;</el-link>
              </div>
      </div>
<!--      <div class="abstract">摘要：{{article.abstract}}</div>-->
      <div class="content" ref="content" v-html="article.body"></div>
      <el-dialog title="提示" :visible.sync="deleteFlag" width="30%" center>
        <span class="prompt"><i class="el-icon-warning"></i>确定删除？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="deleteFlag = false">取 消</el-button>
          <el-button type="primary" @click="deleteFlag = false">确 定</el-button>
        </span>
      </el-dialog>
    <el-dialog title="新建标签" width="30%" :visible.sync="showAddMarkFlag">
      <el-form :model="addMark_form" ref="addMark_form">
        <el-form-item label="标签组:" label-width="80" prop="region" style="padding-left: 13px;">
          <el-select v-model="addMark_form.region" placeholder="请选择标签组" value-key="id" >
            <el-option   v-for = "item in markgrou" :key="item.id" :label = "item.mgroupName" :value="item.mgroupId">{{item.mgroupName}}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签名称:" label-width="80" prop="name">
          <el-input v-model="addMark_form.name" autocomplete="off" style="width: 220px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelSaveMark">取 消</el-button>
        <el-button type="primary" @click="saveMark">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="showOneDeleteFlag" width="30%" center>
      <span class="prompt"><i class="el-icon-warning"></i>确定删除该新闻组及其子新闻？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelOneDelete">取 消</el-button>
        <el-button type="primary" @click="confirmOneDelete">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { setTrack } from '@/api/dataTrack'
import { saveMarkRelationship, removeAllMark, saveMark, listMarkGroupByCid, selectAllType, selectnNodeBySn, PushNews } from '@/api/dataOutput'
import { eventEvolutionNewsDetails, batchUpdateEmotion, setSubject, batchDeleteNews, batchSetIsTag } from '@/api/detection'
export default {
  data () {
    return ({
      fullscreenLoading: false,
      showClassifyFlag: false,
      showPanel: false,
      showOneEmotionPanel: false,
      newsGroupPanel: false,
      showAddMarkFlag: false,
      isIndeterminate: false,
      checkAll: false,
      deleteFlag: false,
      checkedMark: [], // 存储选中的标签id
      addMark_form: { // 新建标签的表单
        name: '',
        region: ''
      },
      markgrou: [],
      cities: ['上海', '北京', '广州', '深圳', '澳门', '香港', '宁波', '邯郸'],
      article: {},
      sm: '',
      listSN: [],
      showOneMarkPanelFlag: false,
      dataList: [],
      options: [],
      showOneDeleteFlag: false,
      el_show: false
    })
  },
  mounted () {
    this.sn = this.$route.params.sn
    this.listSN.push(this.sn)
    this.viewGetNewsDetailsThroughSn(this.sn)
    this.viewlistMarkGroupByCid()
    this.listAllNode()
    console.log(this.article)
  },
  methods: {
    formatDate (time) {
      if (typeof (time) === 'string' && time.indexOf('T') === -1) {
        time = time.replace(/-/g, '/').substr(0, time.indexOf('.'))
      }
      let date = new Date(time)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let hour = date.getHours()
      let min = date.getMinutes()
      let second = date.getSeconds()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      if (hour < 10) {
        hour = '0' + hour
      }
      if (min < 10) {
        min = '0' + min
      }
      if (second < 10) {
        second = '0' + second
      }
      return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + second
    },
    async viewPushNews () {
      this.handleLeave()
      const res = await PushNews({ snlist: this.listSN })
      if (res.code === 200) {
        this.$message({
          message: '推送成功',
          type: 'success'
        })
        this.viewGetNewsDetailsThroughSn(this.sn)
      } else if (res.code === 400) {
        this.$message.error('请勿重复推送')
      } else {
        this.$message.error('推送失败')
      }
    },
    // 追踪数据
    setTrack () {
      this.trackList = []
      this.trackList.push(this.sn)
      this.$confirm('是否要追踪这条新闻?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await setTrack('list=' + this.trackList)
          if (res.code === 0) {
            this.$message.success(res.msg)
          }
        }).catch(() => {

        });

      this.handleLeave()
    },
    async setHandledOne () {
      const res = await batchSetIsTag({ newsList: [{ sn: this.sn }] })
      if (res.code === 0) {
        this.$message({
          message: '所选新闻已设置为已处理',
          type: 'success'
        })
        this.viewGetNewsDetailsThroughSn(this.sn)
      } else {
        this.$message.error('设置已处理操作失败')
      }
    },
    // 取消单独删除
    cancelOneDelete () {
      this.showOneDeleteFlag = false
    },
    // 确定单独删除
    async confirmOneDelete () {
      const res = await batchDeleteNews({ newsList: [{ sn: this.sn }] })
      if (res.code === 0) {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.$router.push(`/warningMessage`)
      } else {
        this.$message.error('删除失败')
      }
      this.showOneDeleteFlag = false
    },
    showOneDelete () {
      // this.oneDeleteId = row.id
      this.showOneDeleteFlag = true
    },
    async UpdateNew () {
      this.handleLeave(this.sn)
      const res = await selectnNodeBySn({ sn: this.sn })
      console.log('580' + res)
      this.$router.push(`/warningMessage`)
    },
    async listAllNode () {
      const res = await selectAllType()
      this.dataList = res.data
    },
    async changeOneNode () {
      var selected = []
      selected.push(this.sn)
      const res = await setSubject({ snArray: selected, msId: -1, msNodeId: this.changeNodeId })
      if (res.code === 0) {
        this.$message.success('划分分类成功')
        this.viewGetNewsDetailsThroughSn(this.sn)
      } else {
        this.$message.error('划分分类失败')
      }
    },
    toNode (item) {
      this.changeNodeId = item.id
    },
    showNode () {
      this.showClassifyFlag = true
      this.hideOneMarkPanel()
      this.hideOneEmotionPanel()
    },
    async viewlistMarkGroupByCid () {
      try {
        const res = await listMarkGroupByCid()
        this.markgrou = res.data
        // console.log(res)
      } catch (err) {
        console.log(err)
      }
    },
    cancelSaveMark () {
      this.showAddMarkFlag = false
      this.$refs.addMark_form.resetFields()
    },
    async viewSaveMark () {
      try {
        const res = await saveMark({ groupId: this.addMark_form.region, markName: this.addMark_form.name })
        if (res.code === 0) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.viewGetNewsDetailsThroughSn(this.sn)
        } else {
          this.$message.error(res.msg)
        }
        this.viewlistMarkGroupByCid()
        console.log(res)
      } catch (e) {
        console.log(e)
      }
    },
    saveMark () {
      this.showAddMarkFlag = false
      this.viewSaveMark()
      this.$refs.addMark_form.resetFields()
    },
    async setOneMark () {
      this.handleLeave()
      const res = await saveMarkRelationship({ snArray: this.listSN, markId: this.checkedMark })
      if (res.code === 200) {
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.viewGetNewsDetailsThroughSn(this.sn)
      } else {
        this.$message.error(res.msg)
      }
    },
    async cancelAllMarkOne () {
      this.checkAllOne = false
      this.isIndeterminateOne = false
      this.checkedMarkOne = []
      const res = await removeAllMark({ sns: this.listSN })
      if (res.code === 0) {
        this.$message({
          message: res.msg,
          type: 'success'
        })
      } else {
        this.$message.error(res.msg)
      }
    },
    hideOneMarkPanel () {
      this.showOneMarkPanelFlag = false
    },
    hideOneEmotionPanel () {
      this.showOneEmotionPanel = false
    },
    hideNode () {
      this.showClassifyFlag = false
    },
    showOneMarkPanel () {
      this.showOneMarkPanelFlag = true
      this.hideNode()
      this.hideOneEmotionPanel()
    },
    async setOneEmotion (emotionType) {
      this.handleLeave()
      const res = await batchUpdateEmotion({ newsList: [{ sn: this.sn }], type: emotionType })
      if (res.code === 0) {
        this.$message({
          message: '情感状态设置成功',
          type: 'success'
        })
        this.viewGetNewsDetailsThroughSn(this.sn)
      } else {
        this.$message.error('情感状态设置失败')
      }
    },
    async viewGetNewsDetailsThroughSn (sns) {
      this.fullscreenLoading = true
      const res = await eventEvolutionNewsDetails({ sn: sns })
      if(res.code == 1){
        this.el_show = true
        this.article = res.data[0]
      }else if(res.code == 500){
        this.$message.error('服务器异常，请稍后再试')
      }
      this.fullscreenLoading = false
    },
    handleLeave () {
      this.showPanel = false
      this.hideNode()
      this.hideOneEmotionPanel()
      this.hideOneMarkPanel()
    },
    // 单独操作-标签全选
    handleCheckAllChange (val) {
      this.checkedMark = val ? this.cities : []
      this.isIndeterminate = false
    },
    // 单独操作-标签选择
    handleCheckedCitiesChange (value) {
      console.log(value)
      let checkedCount = value.length
      this.checkAll = checkedCount === this.cities.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length
    },
    // 单独操作-移除所有标签
    cancelAllMark () {
      this.checkAll = false
      this.isIndeterminate = false
      this.checkedMark = []
    }
  }
}
</script>

<style lang="scss">
.detail-wrap {
  width: 100%;
  // height: 100%;
  min-height: calc(100vh - 160px);
  border-radius: 2px;
  background: #fff;
  box-shadow: 5px 5px 10px rgba(228, 228, 228, .35);
  padding: 15px 20px;
  // margin: 0 15px;
  position: relative;
  .title {
    width: 70%;
    margin-left: 15px;
    color: #333333;
    display: inline-block;
  }
  .tags {
    display: inline-block;
    position: absolute;
    top: 30px;
    right: 15px;
    .el-tag--mini {
      height: 20px;
      padding: 0 5px;
      line-height: 18px;
      font-size: 14px;
      margin-left: 5px;
    }
    .operation {
      margin: 0 15px;
      font-size: 20px;
      margin-bottom: 10px;
      position: relative;
      // font-weight: bold;
      .oneOperationPanel {
        width: 115px;
        // height: 275px;
        position: absolute;
        background-color: #ffffff;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        right: 0px;
        z-index: abs($number: 999);

        .oneOperationUl {
          padding-left: 0px;
          line-height: 2.5;
          font-size: 14px;

          li {
            text-align: left;
            padding-left: 15px;
            color: rgb(14, 13, 13);
          }

          li:hover {
            background-color: rgb(203, 206, 209);
            cursor: pointer;
          }
        }
      }
      .showNode {
        width: 200px;
        // height: 200px;
        position: absolute;
        background-color: #ffffff;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        top: 70px;
        right: 115px;
        z-index: abs($number: 999);

        .oneEmotionUl {
          padding-left: 0px;
          line-height: 2.5;
          font-size: 14px;

          li {
            text-align: center;
            padding-left: 0px;
          }

          li:hover {
            background-color: rgb(203, 206, 209);
            cursor: pointer;
          }
        }
      }
      .showOneEmotionPanel {
        width: 60px;
        height: 103px;
        position: absolute;
        background-color: #ffffff;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        top: 0px;
        right: 116px;
        z-index: abs($number: 999);

        .oneEmotionUl {
          padding-left: 0px;
          line-height: 2.5;
          font-size: 14px;

          li {
            text-align: center;
            padding-left: 0px;
          }

          li:hover {
            background-color: rgb(203, 206, 209);
            cursor: pointer;
          }
        }
      }

      .oneMarkPanel {
        position: relative;

        .mark-wrap-one {
          width: 300px;
          height: 330px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 2px 2px 6px 0px rgba(163, 163, 163, 0.5);
          position: absolute;
          top: -17px;
          right: 116px;
          padding: 15px;
          border: 1px #e6e0e0 solid;

          .table-wrap-one {
            height: 200px;
            border: 1px solid #979797;
            overflow-x: auto;
            overflow-y: auto;

            .mark-table-one {
              display: flex;

              .table-col-one {
                padding: 0;
                border-right: 1px solid #979797;
                flex-grow: 1;
                flex-shrink: 0;

                &:last-child {
                  border-right: none;
                }

                .mark-header-one {
                  border-bottom: 1px solid #979797;
                }

                li {
                  text-align: left;
                  padding-left: 15px;
                  width: 100px;
                }
              }
            }
          }

          .table-bottom-one {
            padding-top: 5px;
          }
        }
      }

      .newsGroupPanelOne {
        width: 110px;
        height: 68px;
        position: absolute;
        background-color: #ffffff;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        top: 207px;
        right: 116px;
        z-index: abs($number: 999);

        .oneNewsGroupUl {
          padding-left: 0px;
          line-height: 2.5;
          font-size: 14px;

          li {
            text-align: left;
            padding-left: 13px;
          }

          li:hover {
            background-color: rgb(203, 206, 209);
            cursor: pointer;
          }
        }
      }

      .bottomEllipsis {
        .oneOperationPanel {
          bottom: 32px;
        }
      }
    }
  }
  .abstract {
    margin: 15px;
  }
  .msg {
    span.dateTime, span.source {
      display: inline-block;
      margin: 0 15px;
      color: #a69999;
      font-size: 16px;
    }
  }
  .content {
    margin: 20px 15px;
    line-height: 3;
  }
  /* .el-link:hover {
    text-decoration: none;
  } */
}
</style>
